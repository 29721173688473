import React, { useEffect, useState } from 'react';

// Функция для очистки имени файла от специальных символов
const cleanFileName = (fileName) => {
  // Удаляем все символы, кроме букв, цифр и точек
  return fileName.replace(/[^a-zA-Z0-9.]/g, '');
};

const MainSlider = () => {
  const [activeSlide, setActiveSlide] = useState(0);
  const [slideImages, setSlideImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const intervalDuration = 20000;
  const token = '91ce7d70742ec648cced1a3766f19691';

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const response = await fetch('https://paradigm-music.ru/api/1.1/obj/releases?constraints=[{"key":"label","constraint_type":"equals","value":"METRIKA MUSIC"},{"key":"StatusRelease","constraint_type":"equals","value":"Выпущенные"},{"key":"master_release","constraint_type":"equals","value":"yes"},{"key":"release-date","constraint_type":"is_not_empty"}]&sort_field=release-date&descending=true&limit=6', {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data && data.response && Array.isArray(data.response.results)) {
          const imageUrls = data.response.results.map(item => item['cover-art']);
         // console.log('Original cover-art URLs:', imageUrls);

          const formattedImageUrls = imageUrls.map(url => {
            const decodedUrl = decodeURIComponent(url);
            const filename = decodedUrl.split('/').pop();
            
            // Очистка имени файла
            const cleanFilename = cleanFileName(filename);
            const cloudFrontUrl = 'https://d15lyydhgewrm7.cloudfront.net';
            const formattedUrl = `${cloudFrontUrl}/images/${encodeURIComponent(cleanFilename)}`;

           // console.log('Formatted cover-art URL:', formattedUrl);
            return formattedUrl;
          });

          setSlideImages(formattedImageUrls);
        } else {
          throw new Error('Unexpected response format');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching images:', error);
        setError(error);
        setLoading(false);
      }
    };

    fetchImages();
  }, [token]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveSlide(prevIndex => (prevIndex === slideImages.length - 1 ? 0 : prevIndex + 1));
    }, intervalDuration);

    return () => clearInterval(intervalId);
  }, [slideImages]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  if (slideImages.length === 0) {
    return <div>No available images</div>;
  }

  return (
    <div className="slider-container">
      {slideImages.map((imageUrl, index) => (
        <div
          key={index}
          className={`slider-item ${index === activeSlide ? 'active' : ''}`}
        >
          <img src={imageUrl} alt="" style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'center top' }} />
        </div>
      ))}
      <button className="slider-btn prev" onClick={() => setActiveSlide(prevIndex => (prevIndex === 0 ? slideImages.length - 1 : prevIndex - 1))}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M15 18l-6-6 6-6" />
        </svg>
      </button>
      <button className="slider-btn next" onClick={() => setActiveSlide(prevIndex => (prevIndex === slideImages.length - 1 ? 0 : prevIndex + 1))}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M9 18l6-6-6-6" />
        </svg>
      </button>
      <div className="slider-dots">
        {slideImages.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === activeSlide ? 'active' : ''}`}
            onClick={() => setActiveSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default MainSlider;
