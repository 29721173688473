import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, NavLink } from "react-router-dom";
import logo from '../../logo.svg';
import './react-router-route.css';
import MainSlider from '../MainSlider';
import BubbleRequest from '../BubbleRequest';
import { Link, Element } from 'react-scroll';

const Home = () => (
  <div id="home">
    <div><MainSlider /></div>
    <div className="music" id="music">
      <BubbleRequest />
    </div>
  </div>
);

const About = () => <div id="about"></div>;
const Music = () => (
  <div>
    <Element name="music"></Element>
  </div>
);
const Artists = () => <div id="artists"></div>;
const Events = () => <div id="events"></div>;
const Contacts = () => <div id="contacts"></div>;
const SendDemo = () => <div id="send-demo"></div>;
const NotFound = () => <div></div>;

const ReactRouterRoute = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const handleScrollToTop = () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const homeLink = document.getElementById("home-link");
    homeLink.addEventListener("click", handleScrollToTop);
    return () => {
      homeLink.removeEventListener("click", handleScrollToTop);
    };
  }, []);

  return (
    <Router>
      <div className="header-wrapper">
        <header className="header">
          <div className="logo">
            <img src={logo} alt="Logo" className="logo-image" />
          </div>
          <nav className={`nav ${menuOpen ? 'open' : ''}`}>
            <ul className="menu">
              <li className="menu-item">
                <NavLink id="home-link" to="/" className={({ isActive }) => isActive ? "is-active" : ""}>HOME</NavLink>
              </li>
              <li className="menu-item">
                <Link to="music" smooth={true} duration={500} className="link-class-name">MUSIC</Link>
              </li>
              <li className="menu-item">
                <NavLink to="https://www.labelradar.com/labels/metrikamusic/portal" target="_blank" className={({ isActive }) => isActive ? "is-active" : ""}>SEND DEMO</NavLink>
              </li>
              <li className="menu-item disabled">
                <NavLink to="/artists" className={({ isActive }) => isActive ? "is-active" : ""} onClick={(e) => e.preventDefault()}>ARTISTS</NavLink>
              </li>
              <li className="menu-item disabled">
                <NavLink to="/events" className={({ isActive }) => isActive ? "is-active" : ""} onClick={(e) => e.preventDefault()}>EVENTS</NavLink>
              </li>
              <li className="menu-item disabled">
                <NavLink to="/contacts" className={({ isActive }) => isActive ? "is-active" : ""} onClick={(e) => e.preventDefault()}>CONTACTS</NavLink>
              </li>
              <li className="menu-item disabled">
                <NavLink to="/about" className={({ isActive }) => isActive ? "is-active" : ""} onClick={(e) => e.preventDefault()}>ABOUT</NavLink>
              </li>
            </ul>
          </nav>
          <div className="burger-menu" onClick={toggleMenu}>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
            <div className="burger-bar"></div>
          </div>
        </header>
      </div>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/music" element={<Music />} />
        <Route path="/artists" element={<Artists />} />
        <Route path="/events" element={<Events />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/send-demo" element={<SendDemo />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default ReactRouterRoute;
