import React from 'react';
import './App.css';
import ReactRouterRoute from './routing/react-router-route';

function App() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <div class="main-content"><ReactRouterRoute /></div>
      <footer>
        &copy; METRIKA MUSIC, {currentYear}
      </footer>
    </>
  );
}

export default App;
